import * as Sentry from "@sentry/vue";
import { createChatWoot } from '@productdevbook/chatwoot/vue'

window.vueAppSetupHook = function(app){
    Sentry.init({
        app,
        dsn: "https://c95649f412f37695c36b6986bb563652@o4507102829543424.ingest.de.sentry.io/4507136228393040",
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration()
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [/^http:\/\/solidtime\.test\/api/, /^https:\/\/app\.solidtime\.io\/api/, /^https:\/\/app\.staging\.solidtime\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    const chatwoot = createChatWoot({
        init: {
            websiteToken: 'vBxLnASupJVBVzy7e36BsDRA',
            baseUrl: 'https://chat.solid-services.eu'
        },
        settings: {
            locale: 'en',
            position: 'right',
            launcherTitle: 'Feedback'
        },
        partytown: false,
    })
    app.use(chatwoot)
}
